<template>
  <f7-page>
    <meta name="robots" content="noindex" />
  </f7-page>
</template>

<script>
import { defineComponent, onMounted, computed, inject } from "vue";
import { helpers } from "@/utils/helpers.js";
import { configs } from "@/utils/configs.js";
import { useStore } from "@/store";

export default defineComponent({
  name: "ReferralView",
  components: {},
  props: {
    f7route: Object,
    f7router: Object,
  },
  setup(props) {
    const store = useStore();
    const userLogged = computed(() => store.getters["user/getToken"]);
    const currentLanguageCode = computed(() => store.getters["translation/getLanguage"]);
    let isWechat = helpers.isInsideWeixin();
    let isMobile = helpers.isMobile();

    onMounted(() => {
      if (!props.f7route?.query?.code) return;
      store.dispatch("referral/setData", { ReferralCode: props.f7route.query.code });

      if (!(userLogged.value == "")) {
        //user already loggged redirect to shop...
        props.f7router.navigate("/shop/", { reloadCurrent: true });
      }

      if (userLogged.value == "" && !isWechat) {
        props.f7router.navigate("/register/", { reloadCurrent: true });
      }

      if (userLogged.value == "" && isWechat) {
        let url = `${configs.baseURL}/webhook/wechat/login${isWechat ? "?wechat=1" : "?auth=1"}`;
        url += "&lang=" + currentLanguageCode.value;
        window.location.href = url;
        return;
      }

      // props.f7router.navigate('/login/', { reloadCurrent: true });
    });

    return {};
  },
});
</script>
